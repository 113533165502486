<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
      <b-link class="brand-logo">
        <img src="@/assets/images/dundar-logo.png" width="155" alt="Dundar Law" style="max-width: 100%; vertical-align: middle; line-height: 100%; border: 0;">
      </b-link>

        <b-card-title class="mb-1">
          {{ $t('login.forgot_password') }} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('login.forgot_password_info') }}
        </b-card-text>

        <!-- form -->
        <validation-observer 
        ref="simpleRules"
        #default="{invalid}">
          <b-form
            class="auth-forgot-password-form mt-2"
          >
            <!-- email -->
            <label for="username">{{ $t('login.username') }}</label>
            <b-form-group>
                                      <validation-provider
            #default="{ errors }"
            :name=" $t('login.username')"
            rules="required|email"
          >
              <b-form-input
                id="username"
                placeholder="test@dundarlaw.com"
                v-model="email"
              />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="invalid"
              @click.prevent="send"
            >
              {{ $t('login.send') }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> {{ $t('login.turn_on_login') }}
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { required, email } from '@validations'

export default {
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
    }
  },
  methods: {
    send() {
        this.sendMail()
    },
  sendMail(){
          const data = {mail: this.email}
          const controller = {name : 'Employee', actionName:'ForgottenPasswordMail'}
          const payload = {data: data, controller: controller}
          this.$store.dispatch('moduleAuth/customService',payload).then((response) => {
           if(response.data.resultStatus == true){
            this.$MailSendAlert()
            this.$router.push({ name: 'login' })
           }
           else{
            this.$WarningAlert(response.data.resultMessage)
            }
          })
     },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
